<template>
    <modal-general
        ref="modalMedicionesDispositivos"
        title="Mediciones del dispositivo"
        size="lg"
        cerrar
    >
        <template v-slot:default>
            <div class="overflow-auto" style="height: calc(100vh - 30vh)">
                <div>
                    <b-row>
                        <b-col>
                            <h4>Variaciones de uso en el mes</h4>
                            <b-card no-body :busy="!showGrafica">
                                <b-row class="p-1">
                                    <b-col>
                                        <v-select
                                            :options="[
                                                'Último año',
                                                'Mes anterior',
                                                'Mes actual',
                                                'Semana actual',
                                                'Hoy',
                                            ]"
                                        ></v-select>
                                    </b-col>
                                    <b-col
                                        ><b-form-datepicker
                                            placeholder="Fecha inicio"
                                        ></b-form-datepicker>
                                    </b-col>
                                    <b-col
                                        ><b-form-datepicker
                                            placeholder="Fecha fin"
                                        ></b-form-datepicker>
                                    </b-col>
                                    <b-col cols="auto">
                                        <b-button variant="primary" pill>
                                            <feather-icon
                                                icon="SearchIcon"
                                            ></feather-icon>
                                        </b-button>
                                    </b-col>
                                </b-row>

                                <b-card-body>
                                    <vue-apex-charts
                                        v-if="showGrafica"
                                        type="line"
                                        height="400"
                                        :options="
                                            apexChatData.lineChartSimple
                                                .chartOptions
                                        "
                                        :series="
                                            apexChatData.lineChartSimple.series
                                        "
                                    />

                                    <div class="text-center">
                                        <b-spinner
                                            v-if="!showGrafica"
                                            variant="primary"
                                        ></b-spinner>
                                    </div>
                                </b-card-body>
                            </b-card>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <h4>Historial</h4>
                            <b-card>
                                <tabla-general
                                    :columns="tableColumns"
                                    :items="tableItems"
                                    :search-activated="false"
                                ></tabla-general>
                            </b-card>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </template>
    </modal-general>
</template>
<script>
import apexChatData from "@/components/apexChartData.js";
import { ref, onMounted, onUnmounted, onActivated } from "@vue/composition-api";
export default {
    setup(props, context) {
        const showGrafica = ref(false);

        const tableColumns = ref([
            { key: "fecha", sortable: true },
            { key: "medicion", sortable: true, label: "Medición" },
            { key: "estado", sortable: true },
        ]);

        const tableItems = ref([
            {
                fecha: "22/05/2022",
                medicion: 23,
                estado: "Normal",
            },
            {
                fecha: "25/05/2022",
                medicion: 23,
                estado: "Regular",
            },
            {
                fecha: "30/05/2022",
                medicion: 23,
                estado: "Mala",
            },
        ]);

        function toggle() {
            showGrafica.value = false;

            context.refs.modalMedicionesDispositivos.toggle();

            setTimeout(() => {
                showGrafica.value = true;
            }, 2000);
        }

        return { apexChatData, showGrafica, tableColumns, tableItems, toggle };
    },
};
</script>
